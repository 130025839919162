import {
	Form,
	Link,
	useFetcher,
	useRouteLoaderData,
	useSubmit,
} from '@remix-run/react'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Drawer } from 'vaul'
import { Logo, LogoMarkSvg } from '#app/components/logo.js'
import { RenderIf } from '#app/components/render-if.tsx'
import { Button } from '#app/components/ui/button.tsx'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuPortal,
	DropdownMenuRadioGroup,
	DropdownMenuRadioItem,
	DropdownMenuTrigger,
} from '#app/components/ui/dropdown-menu.tsx'
import { Icon } from '#app/components/ui/icon.tsx'
import { isUserAdmin, type loader } from '#app/root.js'
import { getUserImgSrc } from '#app/utils/misc.tsx'
import { useGetNavigationLinks } from '#app/utils/use-get-navigation-links.js'
import { useOptionalUser, useUser } from '#app/utils/user.js'
import { Typography } from './ui/typography'

function UserDropdown() {
	const user = useUser()
	const submit = useSubmit()
	const formRef = useRef<HTMLFormElement>(null)
	const isAdmin = isUserAdmin(user)
	const userImage = getUserImgSrc(user.image?.id)
	const { t } = useTranslation()

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button asChild variant="secondary">
					<Link
						to="/settings/profile"
						// this is for progressive enhancement
						onClick={e => e.preventDefault()}
						className="flex items-center gap-2"
					>
						{userImage ? (
							<img
								className="h-8 w-8 rounded-full object-cover"
								alt={user.firstName ?? undefined}
								src={userImage}
							/>
						) : (
							<Icon name="user-01" className="size-5" />
						)}
						<span className="text-body-sm font-bold">{user.firstName}</span>
					</Link>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuPortal>
				<DropdownMenuContent sideOffset={8} align="end">
					<DropdownMenuItem asChild>
						<Link prefetch="intent" to="/settings/profile">
							<Icon className="text-body-md" name="user-01">
								{t('profile')}
							</Icon>
						</Link>
					</DropdownMenuItem>
					<RenderIf condition={isAdmin}>
						<DropdownMenuItem asChild>
							<Link prefetch="intent" to="/admin">
								<Icon className="text-body-md" name="settings-04">
									Admin
								</Icon>
							</Link>
						</DropdownMenuItem>
					</RenderIf>
					<DropdownMenuItem
						asChild
						// this prevents the menu from closing before the form submission is completed
						onSelect={event => {
							event.preventDefault()
							submit(formRef.current)
						}}
					>
						<Form action="/logout" method="POST" ref={formRef}>
							<Icon className="text-body-md" name="log-out-01">
								<button type="submit">{t('logout')}</button>
							</Icon>
						</Form>
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenuPortal>
		</DropdownMenu>
	)
}

function LanguageDropdown() {
	const data = useRouteLoaderData<typeof loader>('root')
	const fetcher = useFetcher()
	const { i18n } = useTranslation()

	const onValueChange = (lang: string) => {
		i18n.changeLanguage(lang)
		fetcher.submit(null, {
			method: 'POST',
			action: `/settings/change-language/${lang}`,
		})
	}

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="secondary">{data?.locale}</Button>
			</DropdownMenuTrigger>
			<DropdownMenuPortal>
				<DropdownMenuContent align="end">
					<DropdownMenuRadioGroup
						value={i18n.language}
						onValueChange={onValueChange}
					>
						<DropdownMenuRadioItem value="sk">Slovensky</DropdownMenuRadioItem>
						<DropdownMenuRadioItem value="en">English</DropdownMenuRadioItem>
					</DropdownMenuRadioGroup>
				</DropdownMenuContent>
			</DropdownMenuPortal>
		</DropdownMenu>
	)
}

export function MobileDrawer() {
	const { links } = useGetNavigationLinks()
	const closeButtonRef = useRef<HTMLButtonElement>(null)

	return (
		<Drawer.Root direction="right">
			<Drawer.Trigger className="md:hidden" ref={closeButtonRef}>
				<Icon name="menu-01" className="size-6" />
			</Drawer.Trigger>
			<Drawer.Portal>
				<Drawer.Content className="fixed bottom-0 right-0 mt-24 flex h-full w-full flex-col bg-white">
					<div className="h-full flex-1 p-4">
						<div className="mx-auto max-w-md">
							<Drawer.Title className="flex justify-between py-2">
								<LogoMarkSvg className="size-8" />
								<Drawer.Trigger asChild>
									<Icon name="x-close" className="size-6" />
								</Drawer.Trigger>
							</Drawer.Title>
							<div className="mt-8 flex flex-col gap-2">
								{links.map(link => (
									<Typography.H3 asChild key={link.href}>
										<Link
											to={link.href}
											onClick={() => {
												closeButtonRef.current?.click()
											}}
										>
											{link.label}
										</Link>
									</Typography.H3>
								))}
							</div>
						</div>
					</div>
				</Drawer.Content>
			</Drawer.Portal>
		</Drawer.Root>
	)
}

export const Header = () => {
	const user = useOptionalUser()
	const { t } = useTranslation()
	const { links } = useGetNavigationLinks()

	return (
		<header className="container py-6">
			<nav className="flex flex-wrap items-center justify-between gap-4 sm:flex-nowrap md:gap-8">
				<div className="flex items-center gap-8">
					<Logo />
					<div className="hidden items-center gap-4 md:flex">
						{links.map(link => (
							<Typography.Muted asChild key={link.href}>
								<Link
									to={link.href}
									className="transition-colors hover:text-muted-foreground-hover"
								>
									{link.label}
								</Link>
							</Typography.Muted>
						))}
					</div>
				</div>
				<div className="flex items-center gap-2">
					<LanguageDropdown />
					{user ? (
						<UserDropdown />
					) : (
						<Button asChild variant="default">
							<Link to="/login">{t('logIn')}</Link>
						</Button>
					)}
					<MobileDrawer />
				</div>
			</nav>
		</header>
	)
}
