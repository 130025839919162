import { useTranslation } from 'react-i18next'

export const useGetNavigationLinks = () => {
	const { t } = useTranslation()

	const links = [
		{
			href: '/',
			label: t('homepage.header.links.home'),
		},
		{
			href: '/engineers',
			label: t('homepage.header.links.forEngineers'),
		},
		{
			href: '/companies',
			label: t('homepage.header.links.forCompanies'),
		},
		{
			href: '/contact',
			label: t('homepage.header.links.contact'),
		},
	]

	return { links }
}

export const useGetFooterNavigationLinks = () => {
	const { t } = useTranslation()
	const links = [
		{
			href: '/cookies',
			label: t('homepage.footer.links.cookies'),
		},
		{
			href: '/privacy',
			label: t('homepage.footer.links.privacy'),
		},
		{
			href: '/terms',
			label: t('homepage.footer.links.terms'),
		},
	]

	return { links }
}
