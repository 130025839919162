import { useForm, getFormProps } from '@conform-to/react'
import { Link, useFetcher } from '@remix-run/react'
import { useTranslation } from 'react-i18next'
import { Logo } from '#app/components/logo.js'
import { Icon } from '#app/components/ui/icon.tsx'
import { Typography } from '#app/components/ui/typography.js'
import { type action } from '#app/root.js'
import { useOptimisticThemeMode } from '#app/routes/_marketing+/_layout.js'
import { type Theme } from '#app/utils/theme.server.ts'
import {
	useGetFooterNavigationLinks,
	useGetNavigationLinks,
} from '#app/utils/use-get-navigation-links.js'

export function ThemeSwitch({
	userPreference,
}: {
	userPreference?: Theme | null
}) {
	const fetcher = useFetcher<typeof action>()

	const [form] = useForm({
		id: 'theme-switch',
		lastResult: fetcher.data?.result,
	})

	const optimisticMode = useOptimisticThemeMode()
	const mode = optimisticMode ?? userPreference ?? 'system'
	const nextMode =
		mode === 'system' ? 'light' : mode === 'light' ? 'dark' : 'system'
	const modeLabel = {
		light: (
			<Icon name="sun">
				<span className="sr-only">Light</span>
			</Icon>
		),
		dark: (
			<Icon name="moon-01">
				<span className="sr-only">Dark</span>
			</Icon>
		),
		system: (
			<Icon name="laptop-01">
				<span className="sr-only">System</span>
			</Icon>
		),
	}

	return (
		<fetcher.Form method="POST" {...getFormProps(form)}>
			<input type="hidden" name="theme" value={nextMode} />
			<div className="flex gap-2">
				<button
					type="submit"
					className="flex h-8 w-8 cursor-pointer items-center justify-center"
				>
					{modeLabel[mode]}
				</button>
			</div>
		</fetcher.Form>
	)
}

export const Footer = () => {
	const { t, i18n } = useTranslation()
	const { links } = useGetNavigationLinks()
	const { links: footerLinks } = useGetFooterNavigationLinks()
	const language = i18n.language

	return (
		<div className="container pb-5 pt-20">
			<div className="grid gap-12 md:grid-cols-9">
				<div className="flex flex-col gap-4 md:col-span-3">
					<Logo />
					<Typography.Muted>
						{t('homepage.footer.description')}
					</Typography.Muted>
				</div>
				<div className="md:col-span-3 lg:grid lg:grid-cols-2">
					<div className="mb-4 lg:mb-0">
						<Typography.Strong className="mb-2 block">
							{t('homepage.footer.office')}
						</Typography.Strong>
						<Typography.Muted>
							WESTEND TOWER
							<br />
							Dúbravska cesta 1793
							<br />
							841 04 Bratislava
							<br />
							+421 948 849 489
							<br />
							info@spacebrains.tech
						</Typography.Muted>
					</div>
					<div>
						<Typography.Strong className="mb-2 block">
							{t('homepage.footer.billing')}
						</Typography.Strong>
						<Typography.Muted className="mb-4">
							spacebrains s.r.o
						</Typography.Muted>
						{language === 'en' && (
							<Typography.Muted>
								Business ID: 55239196
								<br />
								VAT ID: SK2121909746
							</Typography.Muted>
						)}
						{language === 'sk' && (
							<Typography.Muted>
								IČO: 55239196
								<br />
								DIČ: 2121909746
								<br />
								IČ DPH: SK2121909746
							</Typography.Muted>
						)}
					</div>
				</div>
				<div className="md:col-span-3 lg:grid lg:grid-cols-2">
					<div className="mb-4 lg:mb-0">
						<Typography.Strong className="mb-2 block">
							{t('navigation')}
						</Typography.Strong>
						<ul>
							{links.map(link => (
								<li key={link.href}>
									<Typography.Muted asChild>
										<Link
											to={link.href}
											className="transition-colors hover:text-muted-foreground-hover"
										>
											{link.label}
										</Link>
									</Typography.Muted>
								</li>
							))}
						</ul>
					</div>
					<div>
						<Typography.Strong className="mb-2 block">
							{t('homepage.footer.socials')}
						</Typography.Strong>
						<a
							href="https://www.linkedin.com/company/spacebrains"
							target="_blank"
							rel="noreferrer noopener"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 448 512"
								className="size-6"
							>
								<path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" />
							</svg>
						</a>
					</div>
				</div>
			</div>
			<div className="mt-3 flex flex-col-reverse justify-between border-t pt-3 md:mt-6 md:flex-row md:pt-6">
				<Typography.Muted className="opacity-50">
					Copyright {new Date().getFullYear()} spacebrains
				</Typography.Muted>
				<ul className="mb-4 flex flex-col md:mt-0 md:flex-row md:gap-3">
					{footerLinks.map(link => (
						<li key={link.href}>
							<Typography.Muted asChild>
								<Link
									to={link.href}
									className="transition-colors hover:text-muted-foreground-hover"
								>
									{link.label}
								</Link>
							</Typography.Muted>
						</li>
					))}
				</ul>
			</div>
		</div>
	)
}
